import { SearchProductsQueryVariables } from "@/Apollo/schema";
import { analyticsService } from "@/common/services/analytics/analytics.service";
import { MasterFilter } from "@/features/master-filter/contracts/master-filter.contracts";
import { useIs4025ExperiementActive } from "@/features/master-filter/hooks/useIs4025ExperimentActive";
import { updateSearchQueryVariables as updateSearchQueryVariablesUtil } from "@/features/master-filter/utils/master-filter.utils";
import axios from "axios";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

const MASTER_FILTER_API_ENDPOINT = "/api/master-filter";

type MasterFilterContextType = {
  filter?: MasterFilter;
  updateSearchQueryVariables: (
    variables?: SearchProductsQueryVariables
  ) => SearchProductsQueryVariables | undefined;
  toggleAll: () => Promise<void>;
  toggleDesigner: () => Promise<void>;
};

const MasterFilterContext = createContext<MasterFilterContextType>({
  updateSearchQueryVariables: () => undefined,
  toggleAll: async () => {},
  toggleDesigner: async () => {},
});

type MasterFilterProviderProps = {
  filter?: MasterFilter;
};

export const MasterFilterProvider = ({
  filter,
  children,
}: PropsWithChildren<MasterFilterProviderProps>) => {
  const [internalValue, setInternalValue] = useState<MasterFilter | undefined>(
    filter
  );

  const { isActive, isLoading } = useIs4025ExperiementActive();
  const is4025Active = isLoading ? true : isActive;

  /** Client-side. */
  useEffect(() => {
    /** If there was a filter defined server-side, do nothing. */
    if (filter) {
      return;
    }

    const getMasterFilter = async () => {
      if (is4025Active) {
        return "all";
      }

      const masterFilter = await axios.get(MASTER_FILTER_API_ENDPOINT);
      return masterFilter.data;
    };

    getMasterFilter().then(filter => setInternalValue(filter ?? "all"));
  }, [filter, is4025Active]);

  const toggle = useCallback(async (filter: MasterFilter) => {
    const payload = { "master-filter": filter };
    await axios.post(MASTER_FILTER_API_ENDPOINT, payload);

    setInternalValue(filter);
  }, []);

  const updateSearchQueryVariables = useCallback(
    (variables?: SearchProductsQueryVariables) => {
      return updateSearchQueryVariablesUtil(variables, internalValue);
    },
    [internalValue]
  );

  const value = useMemo<MasterFilterContextType>(() => {
    return {
      filter: internalValue,
      updateSearchQueryVariables,
      toggleAll: async () => {
        analyticsService.trackNavigationMenu("Toon alles");
        await toggle("all");
      },
      toggleDesigner: async () => {
        analyticsService.trackNavigationMenu("Designer");
        await toggle("designer");
      },
    };
  }, [internalValue, toggle, updateSearchQueryVariables]);

  return (
    <MasterFilterContext.Provider value={value}>
      {children}
    </MasterFilterContext.Provider>
  );
};

export const useMasterFilter = () => {
  return useContext(MasterFilterContext);
};
