import { useIsExperimentActive } from "@/common/hooks/experiments.hooks";

export const useIs4025ExperiementActive = () => {
  return useIsExperimentActive(
    "4025_master_filter_change_to_normal_filter",
    "version",
    "4025-A",
    "4025-B"
  );
};
