import { useAuth } from "@/auth";
import { useUniqueVisit } from "@/common/hooks/useUniqueVisit";
import { HeaderAccountMobile } from "@/components/Header/HeaderAccountMobile";
import HeaderCart from "@/components/Header/HeaderCart";
import HeaderChat from "@/components/Header/HeaderChat";
import { HeaderCreateAd } from "@/components/Header/HeaderCreateAd";
import { HeaderFavorites } from "@/components/Header/HeaderFavorites";
import { MegaMenuMobile } from "@/components/mega-menu/mobile/MegaMenuMobile";
import { HeaderSearch } from "@/components/Header/search/HeaderSearch";
import { MasterFilterSwitch } from "@/features/master-filter/components/MasterFilterSwitch";
import { useIs4025ExperiementActive } from "@/features/master-filter/hooks/useIs4025ExperimentActive";
import React from "react";
import NoSSR from "@mpth/react-no-ssr";
import { HeaderNotifications } from "./HeaderNotifications";
import {
  WhoppahSpark as WhoppahSparkLogo,
  WhoppahFullSize as WhoppahFullLogo,
} from "@/assets/icons/Logo";
import { HeaderLogo } from "@/components/Header/HeaderLogo";

type HeaderMobileProps = {
  focused?: boolean;
};

const Logo = ({ focused }: { focused?: boolean }) => {
  const { isLoggedIn } = useAuth();

  return (
    <NoSSR>
      {focused ? (
        <HeaderLogo
          logo={
            isLoggedIn ? (
              <WhoppahSparkLogo className="h-6 self-center" />
            ) : (
              <WhoppahFullLogo className="h-6 w-auto" />
            )
          }
        />
      ) : (
        <HeaderLogo
          logo={
            isLoggedIn ? (
              <WhoppahSparkLogo className="h-7" />
            ) : (
              <WhoppahFullLogo className="h-7 w-auto" />
            )
          }
        />
      )}
    </NoSSR>
  );
};

export const HeaderMobile = ({ focused = false }: HeaderMobileProps) => {
  const { isUnique, finalize: finalizeUniqueness } = useUniqueVisit({
    trackingKey: "mobile-header-search-by-image",
    maxRepeatCount: 3,
  });

  const { isActive, isLoading } = useIs4025ExperiementActive();
  const is4025Active = isLoading ? true : isActive;

  const content = focused ? (
    <div className="flex flex-1 justify-center self-center">
      <Logo focused={focused} />
    </div>
  ) : (
    <>
      <div className="flex-1 self-center">
        <Logo focused={focused} />
      </div>
      <HeaderFavorites />
      <HeaderCart />
      <HeaderAccountMobile />
      <HeaderChat />
      <HeaderNotifications />
      <HeaderCreateAd />
    </>
  );

  const search = focused ? null : (
    <HeaderSearch
      showSearchByImageTooltip={isUnique}
      onSearchByImageClick={() => finalizeUniqueness()}
    />
  );

  return (
    <div className="relative">
      <div className="flex flex-col space-y-4 border-b border-b-[#b0b0b0] border-opacity-40 bg-white px-4 pt-5 md:hidden">
        <div className="relative flex items-center space-x-4">{content}</div>
        <div className="flex items-center space-x-3.5">
          {search}
          <MegaMenuMobile />
        </div>
        {!focused && !is4025Active && <MasterFilterSwitch />}
      </div>
    </div>
  );
};
